import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

function requireAuth (to, from, next) {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // // console.log(store.getters.isLoggedIn)
    if (!store.getters.get_username) {
      next()
      // return
    } else {
      next('/pages/login')
    }
  } else {
    next()
  }
}

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/pages',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: 'Login',
          path: 'login',
          component: () => import('@/views/pages/Login'),
        },
        {
          name: 'Register',
          path: 'register',
          component: () => import('@/views/pages/Register'),
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Rooms',
          path: '',
          beforeEnter: requireAuth,
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/dashboard/pages/Rooms'),
        },
        {
          name: 'Students',
          path: 'students',
          beforeEnter: requireAuth,
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/dashboard/pages/Students'),
        },
        {
          name: 'Teachers',
          path: 'teachers',
          beforeEnter: requireAuth,
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/dashboard/pages/Teachers'),
        },
        {
          name: 'Notifications',
          path: 'components/notifications',
          beforeEnter: requireAuth,
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/dashboard/component/Notifications'),
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/pages/Error'),
        },
      ],
    },
  ],
})
