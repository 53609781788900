import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://scontent-otp1-1.xx.fbcdn.net/v/t31.0-8/21246219_1209104472567511_5121227584155891629_o.jpg?_nc_cat=111&ccb=2&_nc_sid=e3f864&_nc_ohc=Lq2gs2XqNUMAX_6Kil0&_nc_ht=scontent-otp1-1.xx&oh=5043b4b9926787dd3903410013f93b96&oe=6033281F',
    drawer: null,
    username: localStorage.getItem('username') || null,
    permission_level: localStorage.getItem('permission') || 0,
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_SCRIM (state, payload) {
      state.barColor = payload
    },
    SET_USER (state, payload) {
      state.username = payload
      localStorage.setItem('username', state.username)
    },
    SET_LEVEL (state, payload) {
      state.permission_level = payload
      localStorage.setItem('permission', state.permission_level)
    },
  },
  actions: {

  },
  getters: {
    get_username: state => state.username,
    get_permissionLevel: state => state.permission_level,
  },
})
